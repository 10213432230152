import { LoanSimulationRequest } from "../../../../domain/models";

type Json = {
  [key: string]: any;
}

export default class LoanSimulationRequestMapper {
  static toJson(loanSimulation: LoanSimulationRequest): Json {
    return {
      "amount": loanSimulation.amount,
      "days": loanSimulation.days,
      "purpose_id": loanSimulation.purposeId,
      "address": loanSimulation.address,
      "city": loanSimulation.city,
      "state": loanSimulation.state,
      "occupation_cd": loanSimulation.occupationCd,
      "layer": loanSimulation.layer,
      "years_in_address": loanSimulation.yearsInAddress,
      "lives_with_cd": loanSimulation.livesWithCd,
      "people_in_charge": loanSimulation.peopleInCharge,
      "children": loanSimulation.children,
      "monthly_salary": loanSimulation.monthlySalary,
      "total_monthly_income": loanSimulation.totalMonthlyIncome,
      "education_level_cd": loanSimulation.educationLevelCd,
      "vehicle_type_cd": loanSimulation.vehicleTypeCd,
      "vehicle_plates": loanSimulation.vehiclePlates,
      "company": loanSimulation.company,
      "company_phone": loanSimulation.companyPhone,
      "accepted_online_signature": loanSimulation.acceptedOnlineSignature
    }
  }
}