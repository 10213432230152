import { Config, CreditPurpose } from "../../../../domain/models";
import { ConfigEntity } from "../entities";
import { BankMapper, UserMapper, BankAccountMapper } from "./";

export default class ConfigMapper {
  static toModel(entity: ConfigEntity): Config {
    return new Config({
      user: UserMapper.toModel(entity.user),
      bankAccount: entity.bank_account ? BankAccountMapper.toModel(entity.bank_account!) : undefined,
      purposes: entity.purposes.map(purpose => new CreditPurpose({ id: purpose.id, name: purpose.name })),
      banks: entity.banks.map(bank => BankMapper.toModel(bank)),
      minCreditAmount: entity.min_amount,
      maxCreditAmount: entity.max_amount,
      minCreditTerm: entity.min_days,
      maxCreditTerm: entity.max_days,
      onlinePaymentFeeRate: entity.online_payment_fee_rate,
      contactEmail: entity.contact_email,
      contactPhone: entity.contact_phone,
      contactAddress: entity.contact_address,
    })
  }
}