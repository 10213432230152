interface IFee {
  name: string;
  description: string;
  code: string;
  version: string;
}

interface IFeeInfo {
  amount: number;
  fee: IFee;
}

export default class FeeInfo {
  amount: number;
  fee: IFee;

  constructor({
    amount, fee
  }: IFeeInfo) {
    this.amount = amount;
    this.fee = fee;
  }
}