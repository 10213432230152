import { NavLink, useNavigate } from 'react-router-dom';
import { LogoWithText } from '../../assets/images';
import { useAuth, useConfig } from '../../hooks';
import SidebarOption from './SidebarOption';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded';
import PaymentsRoundedIcon from '@mui/icons-material/PaymentsRounded';
import Routes from '../../../config/Routes';
import { WhatsappUtil } from '../../../utils';
import HelpIcon from '@mui/icons-material/Help';

interface SidebarProps {
  className?: string;
}
const Sidebar: React.FC<SidebarProps> = ({ className }) => {
  const { logout } = useAuth();
  const { config } = useConfig();
  const navigate = useNavigate();

  const logoutAction = () => {
    logout();
    navigate(Routes.LOGIN_PATH, { replace: true });
  }

  return (
    <aside className={`${className} fixed flex flex-col w-64 h-screen px-4 py-8 overflow-y-auto bg-white border-r rtl:border-r-0 rtl:border-l dark:bg-gray-900 dark:border-gray-700`}>
      <NavLink to={Routes.HOME_PATH} className="flex justify-center items-center">
        <img className="h-auto w-40" src={LogoWithText} alt="login" />
      </NavLink>
      <div className="flex flex-col justify-between flex-1 mt-6">
        <nav className='space-y-2'>
          <SidebarOption name="Créditos" to={Routes.HOME_PATH} icon={<HomeRoundedIcon />} />
          <SidebarOption name="Cuenta" to={Routes.ACCOUNT_PATH} icon={<Person2RoundedIcon />} />
          <SidebarOption name="Métodos de pago" to={Routes.PAYMENT_METHODS_PATH} icon={<PaymentsRoundedIcon />} />
          <SidebarOption name="Contáctanos" to={WhatsappUtil.contactUrl(config?.contactPhone!)} icon={<HelpIcon />} />
        </nav>
        <button className="flex justify-center text-md items-center text-violet-500 font-bold" onClick={logoutAction}>Cerrar sesión</button>
      </div>
    </aside>
  )
}
export default Sidebar;