import { AccountOption, PageContainer } from "../components";
import DoorBackRoundedIcon from '@mui/icons-material/DoorBackRounded';
import Lock from '@mui/icons-material/Lock';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from "react-router-dom";
import Routes from "../../config/Routes";
import { useAuth, useConfig, usePageTracking } from "../hooks";
import EmailIcon from '@mui/icons-material/Email';
import BadgeIcon from '@mui/icons-material/Badge';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

const AccountPage = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { user } = useConfig();
  usePageTracking();

  const logoutAction = () => {
    logout();
    navigate(Routes.LOGIN_PATH, { replace: true });
  }

  return (
    <PageContainer className="flex-col" pageTitle="Mi cuenta">
      <h1 className="text-4xl font-bold">Mi cuenta</h1>

      <div className="flex flex-col bg-white rounded-lg mt-8 lg:mt-10 p-5 w-full space-y-1 shadow-sm border border-gray-200">
        <h2 className="text-md font-bold mb-5">Información personal</h2>

        <div className="flex-col space-y-10 py-4 px-5 rounded-md text-sm justify-between items-center" >
          <div className="flex flex-row space-x-2 items-center">
            <BadgeIcon className="text-slate-500" />
            <span className="font-medium text-slate-500">{user?.fullName ?? 'N/A'}</span>
          </div>

          <div className="flex flex-row space-x-2 items-center">
            <EmailIcon className="text-slate-500" />
            <span className="font-medium text-slate-500">{user?.email ?? 'N/A'}</span>
          </div>

          <div className="flex flex-row space-x-2 items-center">
            <PhoneAndroidIcon className="text-slate-500"/>
            <span className="font-medium text-slate-500	">{user?.mobilePhone ?? 'N/A'}</span>
          </div>
        </div>

        <div className="border-t border-gray-300"></div>

        <AccountOption icon={<AccountBalanceIcon />} name="Actualizar cuenta bancaria" onClick={() => {
          navigate(Routes.UPDATE_BANK_ACCOUNT_PATH);
        }} />

        <AccountOption icon={<AccountCircleIcon />} name="Actualizar información personal" onClick={() => {
          navigate(Routes.UPDATE_PERSONAL_INFORMATION_PATH);
        }} />

        <AccountOption icon={<Lock />} name="Actualizar contraseña" onClick={() => {
          navigate(Routes.UPDATE_PASSWORD_PATH);
        }} />
      </div>

      <div className="flex flex-col bg-white rounded-lg mt-10 p-5 w-full space-y-1 shadow-sm border border-gray-200">
        <h2 className="text-md font-bold mb-5">General</h2>

        <AccountOption icon={<DoorBackRoundedIcon />} name="Cerrar sesión" onClick={logoutAction} />
      </div>
    </PageContainer>
  )
}

export default AccountPage;