import { ActionTimeTracker } from "../../../../domain/models";
import { ActionTimeTrackerType } from "../../../../domain/models/action_time_tracker";
import { ActionTimeTrackerEntity } from "../entities";

type Json = {
  [key: string]: any;
}

export default class ActionTimeTrackerMapper {
  static toModel(entity: ActionTimeTrackerEntity): ActionTimeTracker {
    return new ActionTimeTracker({
      startTimestampMs: entity.start_timestamp_ms,
      endTimestampMs: entity.end_timestamp_ms,
      type: ActionTimeTrackerMapper.typeCdToType(entity.type_cd),
    });
  }

  static toJson(actionTracker: ActionTimeTracker): Json {
    return {
      "start_timestamp_ms": actionTracker.startTimestampMs,
      "end_timestamp_ms": actionTracker.endTimestampMs,
      "type_cd": ActionTimeTracker.enumToTypeCd(actionTracker.type)
    }
  }

  static typeCdToType = (typeCd: number): ActionTimeTrackerType => {
    switch (typeCd) {
      case 0:
        return ActionTimeTrackerType.signup;
      case 1:
        return ActionTimeTrackerType.loan_creation;
      default:
        throw new Error(`Unknown type: ${typeCd}`);
    }
  }
}