import { FeeInfo } from "../../../../domain/models";
import { FeeInfoEntity } from "../entities";

export default class FeeInfoMapper {
  static toModel(entity: FeeInfoEntity): FeeInfo {
    return new FeeInfo({
      amount: entity.amount,
      fee: {
        name: entity.fee.name,
        description: entity.fee.description,
        code: entity.fee.code,
        version: entity.fee.version
      }
    });
  }
}