import { LoanStatus } from "./loan";

type ChargesData = {
  [key: string]: number;
};

interface ILoanDeprecated {
  id: string;
  createdAt: Date;
  status: LoanStatus;
  amountToPay: number;
  amountApproved?: number;
  amountRequested: number;
  paymentUrl?: string;
  consecutive: number;
  chargesData: ChargesData;
  estimatedPaymentDate: Date;
  depositedAt?: Date;
  receiptUrl?: string;
}

export default class LoanDeprecated {
  id: string;
  createdAt: Date;
  status: LoanStatus;
  amountToPay: number;
  amountApproved?: number;
  amountRequested: number;
  paymentUrl?: string;
  consecutive: number;
  chargesData: ChargesData;
  estimatedPaymentDate: Date;
  depositedAt?: Date;
  receiptUrl?: string;

  constructor({
    id, createdAt, status,
    paymentUrl = undefined, amountToPay, amountRequested,
    amountApproved = undefined, consecutive, chargesData,
    estimatedPaymentDate, depositedAt = undefined,
    receiptUrl = undefined
  }: ILoanDeprecated) {
    this.id = id;
    this.createdAt = createdAt;
    this.status = status;
    this.paymentUrl = paymentUrl;
    this.amountToPay = amountToPay;
    this.amountRequested = amountRequested;
    this.amountApproved = amountApproved;
    this.consecutive = consecutive;
    this.chargesData = chargesData;
    this.estimatedPaymentDate = estimatedPaymentDate;
    this.depositedAt = depositedAt;
    this.receiptUrl = receiptUrl;
  }

  availableForPayment = (): boolean => {
    return this.status === LoanStatus.deposited && this.paymentUrl !== null && this.paymentUrl !== undefined;
  }

  inArrears = (): boolean => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    return this.estimatedPaymentDate < currentDate && this.status === LoanStatus.deposited;
  }

  active = (): boolean => {
    return this.status === LoanStatus.open || this.status === LoanStatus.approved || this.status === LoanStatus.deposited;
  }
}