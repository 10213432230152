import { NavLink } from 'react-router-dom';

interface SidebarOptionProps {
  className?: string;
  name: string;
  to: string;
  icon?: React.ReactNode;
  external?: boolean;
}
const SidebarOption: React.FC<SidebarOptionProps> = ({ className, name, to, icon, external = false }) => {
  const notSelectedProperties = "text-gray-400 transition-colors duration-300 transform dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-gray-200 hover:text-gray-700 rounded-lg";
  const selectedProperties = "text-gray-800 bg-gray-100 dark:bg-gray-800 dark:text-gray-200 rounded-lg";
  const baseProperties = "flex items-center px-4 py-4";

  if(external) {
    return(
      <a
        href={to}
        target="_blank"
        rel="noreferrer"
        className={`${className} ${notSelectedProperties} ${baseProperties}`}>
        {icon}
        <span className="mx-4 font-medium text-medium">{name}</span>
      </a>
    )    
  }

  return (
    <NavLink to={to}
      className={({ isActive }) =>
        isActive ? `${className} ${selectedProperties} ${baseProperties}` : `${className} ${notSelectedProperties} ${baseProperties}`
      }>
      {icon}
      <span className="mx-4 font-medium text-medium">{name}</span>
    </NavLink>
  )
}
export default SidebarOption;