import { Bank, Config, CreditPurpose, User } from "../../../domain/models";
import { ConfigRepository } from "../../../domain/repositories";

export default class MockConfigRepository implements ConfigRepository {
  private static instance: MockConfigRepository;

  private constructor() { }

  static getInstance() {
    if (!this.instance) {
      this.instance = new MockConfigRepository();
    }
    return this.instance;
  }

  checkin(): Promise<[Config?, string?]> {
    return new Promise<[Config?, string?]>((resolve, reject) => {
      setTimeout(() => {
        const user = new User({ id: '1', name: 'John', email: '123@gmail.com', password: 'John Doe', inArrears: false, hasActiveCredit: false, usedAllCreditCapacity: false, personalInformationComplete: false});
        const banks: Bank[] = [
          new Bank({ name: 'Bancolombia', code: 'BCL' }),
          new Bank({ name: 'Banco Colpatria', code: 'BCP' }),
          new Bank({ name: 'Banco de Bogota', code: 'BBG' }),
          new Bank({ name: 'Banco Occidente', code: 'BCC' }),
          new Bank({ name: 'Banco BBVA', code: 'BBV' }),
          new Bank({ name: 'Banco Citibank', code: 'CTB' }),
          new Bank({ name: 'Banco Caja Social', code: 'BSC' }),
          new Bank({ name: 'Banco Itau', code: 'ITA' }),
          new Bank({ name: 'Banco Pichincha', code: 'PCH' }),
          new Bank({ name: 'Banco Agrario', code: 'BGR' }),
          new Bank({ name: 'Bancoomeva', code: 'CMV' }),
          new Bank({ name: 'Banco Popular', code: 'BPP' }),
          new Bank({ name: 'Banco Av Villas', code: 'AVV' }),
          new Bank({ name: 'Banco Davivienda', code: 'DVV' })
        ]
        const creditPurposes: CreditPurpose[] = [
          new CreditPurpose({ id: '1', name: "GASTOS MEDICOS" }),
          new CreditPurpose({ id: '2', name: "PARA VIAJAR" }),
          new CreditPurpose({ id: '3', name: "TENER VIDA CREDITICIA" }),
          new CreditPurpose({ id: '4', name: "PAGO DE FACTURAS" }),
          new CreditPurpose({ id: '5', name: "GASTOS INESPERADOS" }),
          new CreditPurpose({ id: '6', name: "GASTO DE VEHICULO" }),
          new CreditPurpose({ id: '7', name: "PAGO DE ESTUDIOS" }),
          new CreditPurpose({ id: '8', name: "PAGO DE CREDITOS" }),
          new CreditPurpose({ id: '9', name: "INVERSION PARA NEGOCIO" })
        ]

        const config = new Config({
          user,
          purposes: creditPurposes,
          banks,
          minCreditAmount: 150000,
          maxCreditAmount: 500000,
          minCreditTerm: 5,
          maxCreditTerm: 30,
          onlinePaymentFeeRate: 4.0,
          contactAddress: 'Calle 25',
          contactEmail: 'contact@contact.com',
          contactPhone: '3013017722'
        });
        // resolve([undefined, 'Oops hubo un error']);
        resolve([config, undefined]);
      }, 1000)
    });
  }
}