import { ActionTimeTracker, BankAccount, Session, User } from "../../../domain/models";
import { UsersRepository } from "../../../domain/repositories";
import axios, { AxiosError } from 'axios';
import { Constants, Environment, JsonUtil } from "../../../utils";
import LocalStorageTokenRepository from "../LocalStorageTokenRepository";
import { BankAccountEntity, SessionEntity } from "./entities";
import { ActionTimeTrackerMapper, BankAccountMapper, SessionMapper, UserMapper } from "./mappers";
import { updatePersonalInformationPayload } from "../../../domain/repositories/UsersRepository";

export default class RestUsersRepository implements UsersRepository {
  private static instance: RestUsersRepository;

  private constructor() { }

  static getInstance() {
    if (!this.instance) {
      this.instance = new RestUsersRepository();
    }
    return this.instance;
  }

  async login(email: string, password: string): Promise<[Session?, string?]> {    
    try {
      const response = await axios.post<SessionEntity>(`${Environment.backendUrl}/api/v1/sessions`, {
        email, password
      });
      
      return [SessionMapper.toModel(response.data), undefined];
    } catch (error) {
      let mssg = Constants.GENERIC_ERROR_MSSG;
      if(error instanceof AxiosError) {
        mssg = JsonUtil.mssgFromError(error);
      }
      if (Environment.env === 'development') { console.log(error); }
      return [undefined, mssg];
    }
  }

  async signUp(first_name: string, email: string, mobile_phone: string, password: string, actionTimeTracker: ActionTimeTracker): Promise<[Session?, string?]> {
    try {
      const response = await axios.post<SessionEntity>(`${Environment.backendUrl}/api/v1/users`, {
        first_name, email, mobile_phone, password, action_time_tracker: ActionTimeTrackerMapper.toJson(actionTimeTracker)
      });
      return [SessionMapper.toModel(response.data), undefined];
    } catch (error) {
      let mssg = Constants.GENERIC_ERROR_MSSG;
      if (error instanceof AxiosError) {
        mssg = JsonUtil.mssgFromError(error);
      }
      if (Environment.env === 'development') { console.log(error); }
      return [undefined, mssg];
    }
  }

  async updatePassword(oldPassword: string, newPassword: string): Promise<[boolean, string?]> {
    try {
      const token = LocalStorageTokenRepository.getInstance().getToken();
      await axios.patch<any>(`${Environment.backendUrl}/api/v1/users/update_password`, {
        password: oldPassword, new_password: newPassword, t: token
      });
      return [true, undefined];
    } catch (error) {
      let mssg = Constants.GENERIC_ERROR_MSSG;
      if (error instanceof AxiosError) {
        mssg = JsonUtil.mssgFromError(error);
      }
      if (Environment.env === 'development') { console.log(error); }
      return [false, mssg];
    }
  }

  async resetPassword(email: string): Promise<[boolean, string?]> {
    try {
      await axios.post<any>(`${Environment.backendUrl}/api/v1/users/reset_password`, {
        email
      });
      return [true, undefined];
    } catch (error) {
      let mssg = Constants.GENERIC_ERROR_MSSG;
      if (error instanceof AxiosError) {
        mssg = JsonUtil.mssgFromError(error);
      }
      if (Environment.env === 'development') { console.log(error); }
      return [false, mssg];
    }
  };

  async updatePersonalInformation(personalInformation: updatePersonalInformationPayload): Promise<[User?, string?]> {
    try {
      const token = LocalStorageTokenRepository.getInstance().getToken();
      const payload = JsonUtil.removeBlankFields({
        first_name: personalInformation.first_name,
        second_name: personalInformation.second_name,
        first_surname: personalInformation.first_surname,
        second_surname: personalInformation.second_surname,
        birth_date: personalInformation.birth_date,
        mobile_phone: personalInformation.mobile_phone,
        phone: personalInformation.phone,
        gender_cd: personalInformation.gender_cd,
        marital_status_cd: personalInformation.marital_status_cd,
        document_number: personalInformation.document_number,
        document_expedition_date: personalInformation.document_expedition_date,
        document_type_cd: personalInformation.document_type_cd,
        t: token   
      });

      const response = await axios.patch<any>(`${Environment.backendUrl}/api/v1/users/update_personal_information`, payload);
      return [UserMapper.toModel(response.data), undefined];
    } catch (error) {
      let mssg = Constants.GENERIC_ERROR_MSSG;
      if (error instanceof AxiosError) {
        mssg = JsonUtil.mssgFromError(error);
      }
      if (Environment.env === 'development') { console.log(error); }
      return [undefined, mssg];
    }
  };

  async updateBankAccount(bankCode: string, typeCd: number, number: string): Promise<[BankAccount?, string?]> {
    try {
      const token = LocalStorageTokenRepository.getInstance().getToken();
      const payload = JsonUtil.removeBlankFields({
        bank_code: bankCode,
        type_cd: typeCd,
        number: number,
        t: token
      });

      const response = await axios.patch<BankAccountEntity>(`${Environment.backendUrl}/api/v1/users/update_bank_account`, payload);
      return [BankAccountMapper.toModel(response.data), undefined];
    } catch (error) {
      let mssg = Constants.GENERIC_ERROR_MSSG;
      if (error instanceof AxiosError) {
        mssg = JsonUtil.mssgFromError(error);
      }
      if (Environment.env === 'development') { console.log(error); }
      return [undefined, mssg];
    }
  };
}