export enum ActionTimeTrackerType {
  signup = "Registro",
  loan_creation = "Creación de préstamo",
}

interface IActionTimeTracker {
  startTimestampMs: number;
  endTimestampMs: number;
  type: ActionTimeTrackerType;
}

export default class ActionTimeTracker {
  startTimestampMs: number;
  endTimestampMs: number;
  type: ActionTimeTrackerType;

  constructor({ startTimestampMs, endTimestampMs, type }: IActionTimeTracker) {
    this.startTimestampMs = startTimestampMs;
    this.endTimestampMs = endTimestampMs;
    this.type = type;
  }

  static enumToTypeCd = (type: ActionTimeTrackerType): number => {
    switch (type) {
      case ActionTimeTrackerType.signup:
        return 0;
      case ActionTimeTrackerType.loan_creation:
        return 1;
      default:
        throw new Error(`Unknown type: ${type}`);
    }
  }
}