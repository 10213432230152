export default class Routes {
  static HOME_PATH = '/';
  static ACCOUNT_PATH = '/account';
  static LOGIN_PATH = '/login';
  static SIGNUP_PATH = '/signup';
  static RESET_PASSWORD_PATH = '/reset-password';
  static UPDATE_PASSWORD_PATH = '/update-password';
  static NEW_LOAN_PATH = '/new-loan';
  static LOAN_DETAIL_PATH = '/loans/:id';
  static PAYMENT_METHODS_PATH = '/payment-methods';
  static UPDATE_PERSONAL_INFORMATION_PATH = '/update-personal-information';
  static UPDATE_BANK_ACCOUNT_PATH = '/update-bank-account';
  static SIGN_CONTRACT_PATH = '/loans/:id/sign';
}