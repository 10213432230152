import { LoanDeprecated, LoanApplicationRequest, PaginatedModel, LoanApplication, LoanSupportFile, PaymentTransaction, LoanSimulationRequest, LoanSimulation } from "../../../domain/models";
import { LoanApplicationDeprecated } from "../../../domain/models";
import Loan, { LoanStatus } from "../../../domain/models/loan";
import { LoansRepository } from "../../../domain/repositories";

export default class MockLoansRepository implements LoansRepository {
  private static instance: MockLoansRepository;
  chargesData = {
    'amount': 5000,
    'iva': 200,
    'insurance': 300,
    'technology': 3500,
    'administration': 100,
    'interest': 600,
    'total': 9700
  }
  payUrl = "https://secure.epayco.co/payment/methods?transaction=yiDQ3Mhsn7MxNFcci";
  receiptUrl = "https://prestafacil.herokuapp.com/public/credits/66070641d4b0800002b0c358/get_pdf.pdf"

  private constructor() { }

  static getInstance() {
    if (!this.instance) {
      this.instance = new MockLoansRepository();
    }
    return this.instance;
  }

  loansByUser(userId: string, page: number): Promise<[PaginatedModel<Loan>?, string?]> {
    return new Promise<[PaginatedModel<Loan>?, string?]>((resolve, reject) => {
      setTimeout(() => {
        if (userId.includes('error')) {
          resolve([undefined, 'Oops hubo un error']);
        } else {
          //const loanApplication = new LoanApplication({ amount: 100000, amountToPay: 130000, days: 30, chargedInterest: 3000, annualEffectiveInterestRate: 0.3, address: '' });
          const newData: Loan[] = [];

          resolve([new PaginatedModel<Loan>({ data: this.shuffleArray(newData), totalPages: 15, currentPage: page }), undefined]);
        }
      }, 1000);
    });
  }

  loanById(userId: string, loanId: string): Promise<[Loan?, string?]> {
    return new Promise<[Loan?, string?]>((resolve, reject) => {
      setTimeout(() => {
        const loanApplication = new LoanApplication({ amount: 100000, amountToPay: 130000, days: 30, chargedInterest: 3000, annualEffectiveInterestRate: 0.3, address: '' });
        const loan = new Loan({ id: '2', consecutive: 1, createdAt: new Date(), estimatedPaymentDate: new Date(), status: LoanStatus.approved, loanApplication: loanApplication, acceptedOnlineSignature: true, signedContract: false });

        resolve([loan, undefined]);
      }, 0);
    });
  }

  applyForLoan(userId: string, loanApplication: LoanApplicationRequest): Promise<[Loan?, string?]> {
    return new Promise<[Loan?, string?]>((resolve, reject) => {
      setTimeout(() => {
        if (loanApplication.familyReferenceName.includes('error')) {
          resolve([undefined, 'Oops hubo un error']);
        } else {
          const loanApplication = new LoanApplication({ amount: 100000, days: 30, amountToPay: 130000, chargedInterest: 3000, annualEffectiveInterestRate: 0.3, address: '' });
          const loan = new Loan({ id: '11', consecutive: 1, status: LoanStatus.rejected, createdAt: new Date(), estimatedPaymentDate: new Date(), loanApplication: loanApplication, acceptedOnlineSignature: true, signedContract: false });

          resolve([loan, undefined]);
        }
      }, 3000);
    });
  }

  applyForLoanDeprecated(userId: string, loanApplication: LoanApplicationDeprecated): Promise<[LoanDeprecated?, string?]> {
    return new Promise<[LoanDeprecated?, string?]>((resolve, reject) => {
      setTimeout(() => {
        if (loanApplication.familyReferenceName.includes('error')) {
          resolve([undefined, 'Oops hubo un error']);
        } else {
          const loan = new LoanDeprecated({ id: '11', createdAt: new Date(), status: LoanStatus.rejected, amountToPay: 100000, amountRequested: 110000, consecutive: 11, chargesData: this.chargesData, estimatedPaymentDate: new Date(), depositedAt: new Date(), receiptUrl: this.receiptUrl });

          resolve([loan, undefined]);
        }
      }, 3000);
    });
  }

  uploadSupportFile(userId: string, loanId: string, file: File): Promise<[LoanSupportFile?, string?]> {
    return new Promise<[LoanSupportFile, string?]>((resolve, reject) => {
      setTimeout(() => {
        resolve([new LoanSupportFile({ url: 'https://s3.amazonaws.com/prestandonos/loan_files/66a07a6de4ec40d234a6076a/file.png?1721793133' }), undefined]);
        // resolve([false, 'Oops hubo un error']);
      }, 3000);
    });
  };

  paymentTransactions(id: string): Promise<[PaymentTransaction[]?, string?]> {
    return new Promise<[PaymentTransaction[]?, string?]>((resolve, reject) => {
      setTimeout(() => {
        if (id.includes('error')) {
          resolve([undefined, 'Oops hubo un error']);
        } else {
          resolve([[
            new PaymentTransaction({ id: '1', createdAt: new Date(), succeeded: true, amount: 150000 }),
          ], undefined]);
        }
      }, 1000);
    });
  }

  getInvoice(id: string): Promise<[Blob?, string?]> {
    return new Promise<[Blob?, string?]>((resolve, reject) => {
      setTimeout(() => {
        resolve([undefined, 'Error']);
      }, 0);
    });
  }

  simulateLoan(simulationRequest: LoanSimulationRequest): Promise<[LoanSimulation?, string?]> {
    return new Promise<[LoanSimulation?, string?]>((resolve, reject) => {
      setTimeout(() => {
        resolve([undefined, 'Error']);
      }, 3000);
    });
  }

  getContractFile(id: string): Promise<[Blob?, string?]> {
    return new Promise<[Blob?, string?]>((resolve, reject) => {
      setTimeout(() => {
        resolve([undefined, 'Error']);
      }, 0);
    });
  }

  getContractSigningUrl(id: string): Promise<[string?, string?]> {
    return new Promise<[string?, string?]>((resolve, reject) => {
      setTimeout(() => {
        resolve(['https://google.com', 'Error']);
      }, 0);
    });
  }

  private shuffleArray = (array: any[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }
}