import { useNavigate, useLocation } from "react-router-dom";
import { ActionCard, CreditCapacityCard, LoansTable, PageContainer } from "../components";
import { MoneyIcon } from "../assets/images";
import Routes from "../../config/Routes";
import { useAnalyticEvents, useConfig, usePageTracking } from "../hooks";
import { AnalyticEvents } from "../../utils";

const HomePage = () => {
  const navigate = useNavigate();
  const { user } = useConfig();
  const location = useLocation();
  const { logEvent } = useAnalyticEvents();
  usePageTracking();
  const queryParams = new URLSearchParams(location.search);
  const epaycoRef = queryParams.get('ref_payco');
  const errorQueryMessage = queryParams.get('error');

  return (
    <PageContainer pageTitle="Créditos de rápido desembolso" className="flex-col">
      <h1 className="text-4xl">Hola, <span className="font-bold">{user?.firstName ?? ""}</span></h1>

      <div className="flex flex-wrap flex-col sm:flex-row mt-10 mb-5 space-x-0 sm:space-x-5 space-y-4 sm:space-y-0">
        <ActionCard icon={MoneyIcon} name="Solicitar préstamo" disabled={user?.inArrears || user?.usedAllCreditCapacity || user?.hasActiveCredit} onClick={() => {
          logEvent(AnalyticEvents.START_LOAN_APPLICATION_EVENT);
          navigate(Routes.NEW_LOAN_PATH);
        }}/>
      </div>

      <div className="flex flex-wrap flex-col sm:flex-row mb-2 space-x-0 sm:space-x-5 space-y-4 sm:space-y-0">
        <CreditCapacityCard creditCapacity={user?.creditCapacity} />
      </div>

      {
        user?.hasActiveCredit && (
          <p className="px-2 text-sm text-gray-500 text-lg">Recuerda que solo puedes tener un crédito activo a la vez.</p>
        )
      }

      {
        epaycoRef && (
          <div className="bg-violet-100 rounded-lg p-5 mt-5">
            <p className="px-2 text-sm text-gray-500 text-lg">¡Gracias por tu pago! Estamos procesando tu transacción, si necesitas ayuda o tienes alguna pregunta, no dudes en contactarnos. Por favor, espera mientras completamos la transacción. Si necesitas actualizar la información, puedes refrescar la página. ¡Gracias!</p>
          </div>
        )
      }

      {
        errorQueryMessage && (
          <div className="bg-red-100 rounded-lg p-5 mt-5">
            <p className="px-2 text-sm text-gray-500 text-lg">{errorQueryMessage}</p>
          </div>
        )
      }

      <LoansTable />
    </PageContainer>
  )
}

export default HomePage;