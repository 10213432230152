import axios, { AxiosError } from "axios";
import { LoanDeprecated, PaginatedModel, LoanApplicationDeprecated, LoanApplicationRequest, Loan, LoanSupportFile, PaymentTransaction, LoanSimulationRequest, LoanSimulation } from "../../../domain/models";
import { LoansRepository } from "../../../domain/repositories";
import { Constants, Environment, JsonUtil } from "../../../utils";
import LocalStorageTokenRepository from "../LocalStorageTokenRepository";
import { ContractSigningUrlEntity, LoanEntity, LoanEntityDeprecated, LoanSimulationEntity, LoanSupportFileEntity, PaginatedEntity, PaymentTransactionEntity } from "./entities";
import { LoanApplicationRequestMapper, LoanApplicationMapperDeprecated, LoanMapperDeprecated, LoanMapper, LoanSupportFileMapper, PaymentTransactionMapper, LoanSimulationMapper, LoanSimulationRequestMapper } from "./mappers";

export default class RestLoansRepository implements LoansRepository {
  private static instance: RestLoansRepository;

  private constructor() { }

  static getInstance() {
    if (!this.instance) {
      this.instance = new RestLoansRepository();
    }
    return this.instance;
  }

  async loansByUser(userId: string, page: number): Promise<[PaginatedModel<Loan>?, string?]> {
    try {
      const token = LocalStorageTokenRepository.getInstance().getToken();
      const response = await axios.get<PaginatedEntity<LoanEntity>>(`${Environment.backendUrl}/api/v1/loans?t=${token}&page=${page}`);
      const loans = response.data.data.map((loanEntity) => LoanMapper.toModel(loanEntity));
      const paginatedModel = new PaginatedModel<Loan>({
        data: loans,
        totalPages: response.data.totalPages,
        currentPage: response.data.currentPage
      });

      return [paginatedModel, undefined];
    } catch (error) {
      let mssg = Constants.GENERIC_ERROR_MSSG;
      if (error instanceof AxiosError) {
        mssg = JsonUtil.mssgFromError(error);
      }
      if (Environment.env === 'development') { console.log(error); }
      return [undefined, mssg];
    }
  }

  async loanById(userId: string, loanId: string): Promise<[Loan?, string?]> {
    try {
      const token = LocalStorageTokenRepository.getInstance().getToken();
      const response = await axios.get<LoanEntity>(`${Environment.backendUrl}/api/v1/loans/${loanId}?t=${token}`);
      return [LoanMapper.toModel(response.data), undefined];
    } catch (error) {
      let mssg = Constants.GENERIC_ERROR_MSSG;
      if (error instanceof AxiosError) {
        mssg = JsonUtil.mssgFromError(error);
      }
      if (Environment.env === 'development') { console.log(error); }
      return [undefined, mssg];
    }
  }

  async applyForLoan(userId: string, loanApplication: LoanApplicationRequest): Promise<[Loan?, string?]> {
    try {
      const token = LocalStorageTokenRepository.getInstance().getToken();
      const application = JsonUtil.removeBlankFields(LoanApplicationRequestMapper.toJson(loanApplication));
      const response = await axios.post<LoanEntity>(`${Environment.backendUrl}/api/v1/loans?t=${token}`, application);
      return [LoanMapper.toModel(response.data), undefined];
    } catch (error) {
      let mssg = Constants.GENERIC_ERROR_MSSG;
      if (error instanceof AxiosError) {
        mssg = JsonUtil.mssgFromError(error);
      }
      if (Environment.env === 'development') { console.log(error); }
      return [undefined, mssg];
    }
  }

  async applyForLoanDeprecated(userId: string, loanApplication: LoanApplicationDeprecated): Promise<[LoanDeprecated?, string?]> {
    try {
      const token = LocalStorageTokenRepository.getInstance().getToken();
      const application = JsonUtil.removeBlankFields(LoanApplicationMapperDeprecated.toJson(loanApplication));
      const response = await axios.post<LoanEntityDeprecated>(`${Environment.backendUrl}/api/v2/credits?t=${token}`, application);
      return [LoanMapperDeprecated.toModel(response.data), undefined];
    } catch (error) {
      let mssg = Constants.GENERIC_ERROR_MSSG;
      if (error instanceof AxiosError) {
        mssg = JsonUtil.mssgFromError(error);
      }
      if (Environment.env === 'development') { console.log(error); }
      return [undefined, mssg];
    }
  }

  async uploadSupportFile(userId: string, loanId: string, file: File): Promise<[LoanSupportFile?, string?]> {
    try {
      const token = LocalStorageTokenRepository.getInstance().getToken();
      const formData = new FormData();
      formData.append('file', file);

      const response = await axios.post<LoanSupportFileEntity>(
        `${Environment.backendUrl}/api/v1/loans/${loanId}/upload_support_file?t=${token}`,
        formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      return [LoanSupportFileMapper.toModel(response.data), undefined];
    } catch (error) {
      let mssg = Constants.GENERIC_ERROR_MSSG;
      if (error instanceof AxiosError) {
        mssg = JsonUtil.mssgFromError(error);
      }
      if (Environment.env === 'development') { console.log(error); }
      return [undefined, mssg];
    }
  };

  async paymentTransactions(id: string): Promise<[PaymentTransaction[]?, string?]> {
    try {
      const token = LocalStorageTokenRepository.getInstance().getToken();

      const response = await axios.get<PaymentTransactionEntity[]>(`${Environment.backendUrl}/api/v1/loans/${id}/payment_transactions?t=${token}`);
      const transactions = response.data.map((transactionEntity) => PaymentTransactionMapper.toModel(transactionEntity));
      return [transactions, undefined];
    } catch (error) {
      let mssg = Constants.GENERIC_ERROR_MSSG;
      if (error instanceof AxiosError) {
        mssg = JsonUtil.mssgFromError(error);
      }
      if (Environment.env === 'development') { console.log(error); }
      return [undefined, mssg];
    }
  }

  async getInvoice(id: string): Promise<[Blob?, string?]> {
    try {
      const token = LocalStorageTokenRepository.getInstance().getToken();

      const response = await axios.get<Blob>(`${Environment.backendUrl}/api/v1/loans/${id}/invoice?t=${token}`, {
        responseType: 'blob'
      });
      return [response.data, undefined];
    } catch (error) {
      let mssg = Constants.GENERIC_ERROR_MSSG;
      if (error instanceof AxiosError) {
        mssg = JsonUtil.mssgFromError(error);
      }
      if (Environment.env === 'development') { console.log(error); }
      return [undefined, mssg];
    }
  }

  async simulateLoan(simulationRequest: LoanSimulationRequest): Promise<[LoanSimulation?, string?]> {
    try {
      const token = LocalStorageTokenRepository.getInstance().getToken();
      const application = JsonUtil.removeBlankFields(LoanSimulationRequestMapper.toJson(simulationRequest));
      const response = await axios.post<LoanSimulationEntity>(`${Environment.backendUrl}/api/v1/loans/simulate?t=${token}`, application);
      return [LoanSimulationMapper.toModel(response.data), undefined];
    } catch (error) {
      let mssg = Constants.GENERIC_ERROR_MSSG;
      if (error instanceof AxiosError) {
        mssg = JsonUtil.mssgFromError(error);
      }
      if (Environment.env === 'development') { console.log(error); }
      return [undefined, mssg];
    }
  }

  async getContractFile(id: string): Promise<[Blob?, string?]> {
    try {
      const token = LocalStorageTokenRepository.getInstance().getToken();

      const response = await axios.get<Blob>(`${Environment.backendUrl}/api/v1/loans/${id}/contract_file?t=${token}`, {
        responseType: 'blob'
      });
      return [response.data, undefined];
    } catch (error) {
      let mssg = Constants.GENERIC_ERROR_MSSG;
      if (error instanceof AxiosError) {
        mssg = JsonUtil.mssgFromError(error);
      }
      if (Environment.env === 'development') { console.log(error); }
      return [undefined, mssg];
    }
  }

  async getContractSigningUrl(id: string): Promise<[string?, string?]> {
    try {
      const token = LocalStorageTokenRepository.getInstance().getToken();

      const response = await axios.get<ContractSigningUrlEntity>(`${Environment.backendUrl}/api/v1/loans/${id}/contract_signing_url?t=${token}`);
      return [response.data.url, undefined];
    } catch (error) {
      let mssg = Constants.GENERIC_ERROR_MSSG;
      if (error instanceof AxiosError) {
        mssg = JsonUtil.mssgFromError(error);
      }
      if (Environment.env === 'development') { console.log(error); }
      return [undefined, mssg];
    }
  }
}