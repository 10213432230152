import { useState } from "react";
import useNewLoanBankingDataForm from "./useNewLoanBankingDataForm";
import useNewLoanFinancialDataForm from "./useNewLoanFinancialDataForm";
import { useAnalyticEvents, useConfig, useNewLoanReferencesForm } from "..";
import { LoansRepository } from "../../../domain/repositories";
import { RestLoansRepository } from "../../../infrastructure/repositories";
import { Alert, AnalyticEvents, DeviceUtil } from "../../../utils";
import { ActionTimeTracker, LoanApplicationRequest, LoanSimulationRequest } from "../../../domain/models";
import { useNavigate } from "react-router-dom";
import Routes from "../../../config/Routes";
import { ActionTimeTrackerType } from "../../../domain/models/action_time_tracker";

const useApplyForCreditForm = (totalSteps: number) => {
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [startTimestampMs] = useState<number>(Date.now());
  const bankingDataHandler = useNewLoanBankingDataForm();
  const financialDataHandler = useNewLoanFinancialDataForm();
  const referencesDataHandler = useNewLoanReferencesForm({
    onSubmit: () => {
      onSubmit();
    }
  });
  const { user, loadConfig } = useConfig();
  const navigate = useNavigate();
  const { logEvent } = useAnalyticEvents();

  const loansRepository: LoansRepository = RestLoansRepository.getInstance();

  const goToNextStep = () => {
    if (step >= totalSteps - 1) return;

    setStep(step + 1);
  }

  const goToPreviousStep = () => {
    if (step <= 0) return;

    setStep(step - 1);
  }

  const buildSimulationPayload = (): LoanSimulationRequest => {
    const data = {
      ...bankingDataHandler.data(),
      ...financialDataHandler.data(),
      ...referencesDataHandler.data()
    }

    return new LoanSimulationRequest({
      amount: data.amountRequested!,
      days: data.creditDays!,
      purposeId: data.creditPurpose!,
      address: data.address,
      city: data.city,
      state: data.state,
      occupationCd: data.occupation!,
      layer: data.layer!,
      yearsInAddress: data.yearsOfLiving!,
      livesWithCd: data.homeTenants!,
      peopleInCharge: data.peopleInCharge!,
      children: data.numberOfChildren!,
      monthlySalary: data.monthlyIncome!,
      totalMonthlyIncome: data.homeMonthlyIncome!,
      educationLevelCd: data.educationLevel!,
      vehicleTypeCd: data.vehicleType!,
      vehiclePlates: data.vehiclePlate,
      company: data.company,
      companyPhone: data.companyPhone,
      acceptedOnlineSignature: data.acceptedOnlineSignature
    });
  }

  const onSubmit = async () => {
    const data = {
      ...bankingDataHandler.data(),
      ...financialDataHandler.data(),
      ...referencesDataHandler.data()
    }
    const actionTimeTracker = new ActionTimeTracker({
      startTimestampMs,
      endTimestampMs: Date.now(),
      type: ActionTimeTrackerType.loan_creation
    });

    const loanApplication = new LoanApplicationRequest({
      amount: data.amountRequested!,
      days: data.creditDays!,
      purposeId: data.creditPurpose!,
      address: data.address,
      city: data.city,
      state: data.state,
      occupationCd: data.occupation!,
      layer: data.layer!,
      yearsInAddress: data.yearsOfLiving!,
      livesWithCd: data.homeTenants!,
      peopleInCharge: data.peopleInCharge!,
      children: data.numberOfChildren!,
      monthlySalary: data.monthlyIncome!,
      totalMonthlyIncome: data.homeMonthlyIncome!,
      educationLevelCd: data.educationLevel!,
      vehicleTypeCd: data.vehicleType!,
      vehiclePlates: data.vehiclePlate,
      company: data.company,
      companyPhone: data.companyPhone,
      personalReferenceName: data.personalReferenceName,
      personalReferencePhone: data.personalReferencePhone,
      personalReferenceEmail: data.personalReferenceEmail,
      personalReferenceRelation: data.personalReferenceRelationship,
      familyReferenceName: data.familyReferenceName,
      familyReferencePhone: data.familyReferencePhone,
      familyReferenceEmail: data.familyReferenceEmail,
      familyReferenceRelation: data.familyReferenceRelationship,
      bankAccountType: data.bankAccountType!,
      bankCode: data.bankCode!,
      bankAccountNumber: data.bankAccountNumber!,
      acceptedOnlineSignature: data.acceptedOnlineSignature,
      deviceInfo: DeviceUtil.getDeviceInfo(),
      actionTimeTracker: actionTimeTracker
    });

    setLoading(true);
    const [loan, errorMessage] = await loansRepository.applyForLoan(user!.id, loanApplication);
    setLoading(false);

    if (errorMessage) {
      Alert.showError(errorMessage);
    } else {
      logEvent(AnalyticEvents.REQUEST_LOAN_EVENT);
      loadConfig();
      navigate(Routes.LOAN_DETAIL_PATH.replace(':id', loan!.id), { replace: true });
    }
  }

  return {
    step,
    goToNextStep,
    goToPreviousStep,
    bankingDataHandler,
    financialDataHandler,
    referencesDataHandler,
    loading, buildSimulationPayload
  }
};

export default useApplyForCreditForm;