import InfoIcon from '@mui/icons-material/Info';
import ProgressIndicator from './shared/ProgressIndicator';
import { Money } from '../../utils';
import { CreditCapacity } from '../../domain/models';

interface CreditCapacityCardProps {
  className?: string;
  creditCapacity?: CreditCapacity;
}

const CreditCapacityCard: React.FC<CreditCapacityCardProps> = ({ className, creditCapacity }) => {
  if(creditCapacity === undefined || creditCapacity === null) {
    return(
      <div className={`${className} opacity-100 bg-gray-100 rounded-lg py-5 pl-5 pr-10 flex flex-row shadow-sm justify-center items-center space-x-5 border border-gray-200`}>
        <InfoIcon className="text-slate-500" />
        <div className="flex flex-col">
          <span className="text-md font-medium">Aquí verás tu monto preaprobado disponible*</span>
          <span className="text-xs font-thin">Aparecerá una vez tengas tu primer préstamo aprobado</span>
        </div>
      </div>
    )
  } else {
    return (
      <div className={`${className} opacity-100 bg-white rounded-lg py-5 pl-5 pr-10 flex flex-row shadow-sm justify-center items-center space-x-5 border border-gray-200`}>
        <ProgressIndicator value={(creditCapacity.available * 100) / creditCapacity.amount} />
        <div className="flex flex-col">
          <span className="text-2xl font-bold text-violet-500">{Money.formatToCOP(creditCapacity.available)} disponible</span>
          <span className="text-xs font-normal">Tu monto total <strong>preaprobado</strong> para préstamos es de {Money.formatToCOP(creditCapacity.amount)}</span>
        </div>
      </div>
    )
  }
}
export default CreditCapacityCard;