import { NavLink } from "react-router-dom";
import { InputValidator, Number, Selector } from "../../infrastructure/inputs";
import { Constants } from "../../utils";
import { Button, Input, OptionInput, PageContainer } from "../components";
import { useUpdateBankAccount } from "../hooks";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Routes from "../../config/Routes";

interface UpdateBankAccountPageProps {
  className?: string;
}

const UpdateBankAccountPage: React.FC<UpdateBankAccountPageProps> = ({ className = '' }) => {
  const {
    submitForm,
    bankAccountNumber,
    bankAccountType,
    bankCode,
    setBankCode,
    setBankAccountType,
    setBankAccountNumber,
    submitted,
    config,
    loading
  } = useUpdateBankAccount();

  return (
    <PageContainer className="flex-col">
      <h1 className="text-4xl font-bold">Actualizar cuenta bancaria</h1>
      <div className="mt-8 bg-white rounded-lg shadow-lg flex-col flex">
        <NavLink className="flex justify-start pl-10 pt-10 items-center font-bold opacity-65" to={Routes.ACCOUNT_PATH}>
          <ArrowBackIosIcon style={{ fontSize: '1rem' }} className="text-gray-500 hover:text-gray-400" />
          Volver
        </NavLink>

        <form
          className="flex flex-wrap flex-col px-20 pb-20 pt-10 sm:justify-center items-center space-y-16"
          onSubmit={(event) => {
            event.preventDefault();
            submitForm();
          }}
        >
          <div className="flex grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 w-full font-medium gap-5 mt-5">
            <OptionInput
              options={Object.keys(Constants.BANK_ACCOUNT_TYPES).map((key) => ({ value: Constants.BANK_ACCOUNT_TYPES[key], label: key }))}
              selectedOption={bankAccountType.value}
              className=''
              placeholder="Tipo de cuenta"
              errorMessage={submitted ? bankAccountType.errorMessage() : null}
              onChange={(event) => { setBankAccountType(Selector.dirtyWithOptions(event.target.value, Object.values(Constants.BANK_ACCOUNT_TYPES))) }}
            />

            <OptionInput
              options={config?.banks.map(bank => ({ value: bank.code, label: bank.name }))}
              selectedOption={bankCode.value}
              className=''
              placeholder="Banco"
              errorMessage={submitted ? bankCode.errorMessage() : null}
              onChange={(event) => { setBankCode(Selector.dirtyWithOptions(event.target.value, config?.banks.map(bank => bank.code) ?? [])) }}
            />

            <Input
              type="number"
              placeholder="Número de cuenta"
              value={bankAccountNumber.value}
              errorMessage={submitted ? bankAccountNumber.errorMessage() : null}
              onChange={(event) => { setBankAccountNumber(Number.dirty(event.target.value)) }}
            />
          </div>

          <div className="flex justify-end w-full mt-16">
            <Button
              loading={loading}
              disabled={InputValidator.anyEmpty([bankAccountType, bankCode, bankAccountNumber])}
              className="w-auto"
              type="submit"
              text="Actualizar"
            />
          </div>
        </form>
      </div>
    </PageContainer>
  )
}

export default UpdateBankAccountPage;