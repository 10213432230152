import { useEffect } from "react";
import { DateUtil } from "../../utils";
import { useLoanPaymentTransactions } from "../hooks";

interface LoanPaymentTransactionsTableProps {
  loanId: string;
}

const LoanPaymentTransactionsTable: React.FC<LoanPaymentTransactionsTableProps> = ({ loanId }) => {
  const { loadPaymentTransactions, paymentTransactions } = useLoanPaymentTransactions();

  useEffect(() => {
    loadPaymentTransactions(loanId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col px-20 pb-10">
      <div className="border-t border-gray-300 hidden md:block"></div>
      <h2 className="text-xl font-bold my-10">Historial de pagos en línea</h2>
      {
        paymentTransactions.length === 0 ? (
          <div className="flex flex-wrap flex-col justify-center items-center">
            <h2 className="font-bold text-md opacity-50 text-center">Por el momento no tienes pagos</h2>
          </div>
        ) : (
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden border border-gray-200 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-100">
                    <tr>
                      <th className="py-3.5 px-4 text-sm font-normal text-left text-gray-500">ID</th>
                      <th className="py-3.5 px-4 text-sm font-normal text-left text-gray-500">Fecha</th>
                      <th className="py-3.5 px-4 text-sm font-normal text-left text-gray-500">¿Fue exitoso?</th>
                    </tr >
                  </thead >
                  <tbody className="bg-white divide-y divide-gray-200">
                    {
                      paymentTransactions.map((transaction, index) => (
                        <tr key={index}>
                          <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">{transaction.id}</td>
                          <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">{DateUtil.formatDate(transaction.createdAt)}</td>
                          <td className="px-4 py-4 text-sm text-gray-500 whitespace-nowrap">{transaction.succeeded ? 'Sí' : 'No'}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table >
              </div >
            </div >
          </div >
        )
      }
    </div>
  )
}

export default LoanPaymentTransactionsTable;