import { useState } from "react";
import { LoansRepository } from "../../domain/repositories";
import { RestLoansRepository } from "../../infrastructure/repositories";
import { LoanSimulation, LoanSimulationRequest } from "../../domain/models";
import { Alert } from "../../utils";

const useSimulateLoan = () => {
  const [loading, setLoading] = useState(false);
  const [loanSimulation, setSimulationLoan] = useState<LoanSimulation |  undefined>(undefined);

  const loansRepository: LoansRepository = RestLoansRepository.getInstance();

  const simulateLoan = async (simulationRequest: LoanSimulationRequest, acceptedOnlineSignature: boolean) => {
    simulationRequest.acceptedOnlineSignature = acceptedOnlineSignature;

    setLoading(true);
    const [simulation, errorMessage] = await loansRepository.simulateLoan(simulationRequest)
    setLoading(false);

    if (errorMessage) {
      Alert.showError(errorMessage);
    } else {
      setSimulationLoan(simulation);
    }
  }

  return {
    loading, simulateLoan,
    loanSimulation
  }
};

export default useSimulateLoan;