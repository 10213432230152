import { User } from "../../../../domain/models";
import { UserEntity } from "../entities";
import { CreditCapacityMapper } from "./";

export default class UserMapper {
  static toModel(entity: UserEntity): User {
    return new User({
      id: entity.id,
      name: entity.name,
      email: entity.email,
      inArrears: entity.in_arrears,
      hasActiveCredit: entity.has_active_credit,
      usedAllCreditCapacity: entity.used_all_credit_capacity,
      personalInformationComplete: entity.personal_information_complete,
      firstName: entity.first_name,
      secondName: entity.second_name,
      firstSurname: entity.first_surname,
      secondSurname: entity.second_surname,
      birthDate: entity.birth_date,
      mobilePhone: entity.mobile_phone,
      phone: entity.phone,
      genderCd: entity.gender_cd,
      maritalStatusCd: entity.marital_status_cd,
      documentNumber: entity.document_number,
      documentExpeditionDate: entity.document_expedition_date,
      documentTypeCd: entity.document_type_cd,
      creditCapacity: entity.credit_capacity ? CreditCapacityMapper.toModel(entity.credit_capacity) : undefined,
    })
  }
}