export interface DeviceInfo {
  userAgent: string;
  language: string;
  deviceType: 'Android' | 'iOS' | 'Desktop' | 'Unknown';
}

export default class DeviceUtil {
  static getDeviceInfo = (): DeviceInfo => {
    const userAgent = navigator.userAgent;
    const language = navigator.language;

    const isAndroid = /Android/i.test(userAgent);
    const isIOS = /iPhone|iPad|iPod/i.test(userAgent);
    const isDesktop = !isAndroid && !isIOS;

    let deviceType: 'Android' | 'iOS' | 'Desktop' | 'Unknown' = 'Unknown';
    if (isAndroid) {
      deviceType = 'Android';
    } else if (isIOS) {
      deviceType = 'iOS';
    } else if (isDesktop) {
      deviceType = 'Desktop';
    }

    return {
      userAgent,
      language,
      deviceType,
    };
  }

  static isAndroid = (): boolean => {
    return /Android/i.test(navigator.userAgent);
  }

  static isIOS = (): boolean => {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
  }

  static isDesktop = (): boolean => {
    return !this.isAndroid() && !this.isIOS();
  }
}
