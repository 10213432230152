import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Routes from "../../config/Routes";
import { Alert, Constants } from "../../utils";
import { BankAccount, Config } from "../../domain/models";
import { UsersRepository } from "../../domain/repositories";
import { RestUsersRepository } from "../../infrastructure/repositories";
import { InputValidator, Number, Selector } from "../../infrastructure/inputs";
import useConfig from "./useConfig";
import { selectConfig } from "../../infrastructure/redux/selectors";

const useNewLoanBankingDataForm = () => {
  const config: Config | null | undefined = useSelector(selectConfig);
  const { bankAccount, updateBankAccount } = useConfig();
  const [bankAccountType, setBankAccountType] = useState(Selector.dirtyWithOptions(bankAccount?.type_cd.toString() ?? "", Object.values(Constants.BANK_ACCOUNT_TYPES)));
  const [bankCode, setBankCode] = useState(Selector.dirtyWithOptions(bankAccount?.bank?.code ?? "", config?.banks.map(bank => bank.code) ?? []));
  const [bankAccountNumber, setBankAccountNumber] = useState(Number.dirty(bankAccount?.number ?? ""));
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  const usersRepository: UsersRepository = RestUsersRepository.getInstance();

  const submitForm = async () => {
    setSubmitted(true);
    if (!validForm()) return false;

    setLoading(true);
    const [bankAccount, errorMessage]: [BankAccount?, string?] = await usersRepository.updateBankAccount(
      bankCode.value, bankAccountType.getNumber()!, bankAccountNumber.value
    );
    setLoading(false);

    if (errorMessage) {
      Alert.showError(errorMessage);
    } else {
      updateBankAccount(bankAccount!);
      Alert.showSuccess(Constants.UPDATED_BANK_ACCOUNT_CORRECTLY_MSG);
      navigate(Routes.HOME_PATH, { replace: true });
    }
  };

  const validForm = (): boolean => {
    return InputValidator.valid([
      bankAccountType,
      bankCode,
      bankAccountNumber
    ]);
  }

  return {
    bankAccountType, setBankAccountType,
    bankCode, setBankCode,
    bankAccountNumber, setBankAccountNumber,
    submitted, submitForm,
    loading, config
  }
};

export default useNewLoanBankingDataForm;