//import { useState } from "react";

import { useState } from "react";
import { CheckboxInput, Email, InputValidator, Text } from "../../../infrastructure/inputs";
import { Environment } from "../../../utils";

interface INewLoanReferencesForm {
  onSubmit: () => void;
}

const useNewLoanReferencesForm = ({ onSubmit }: INewLoanReferencesForm) => {
  const [personalReferenceName, setPersonalReferenceName] = useState(Text.dirty(""));
  const [personalReferencePhone, setPersonalReferencePhone] = useState(Text.dirty(""));
  const [personalReferenceEmail, setPersonalReferenceEmail] = useState(Email.dirty(""));
  const [personalReferenceRelationship, setPersonalReferenceRelationship] = useState(Text.dirty(""));
  const [familyReferenceName, setFamilyReferenceName] = useState(Text.dirty(""));
  const [familyReferencePhone, setFamilyReferencePhone] = useState(Text.dirty(""));
  const [familyReferenceEmail, setFamilyReferenceEmail] = useState(Email.dirty(""));
  const [familyReferenceRelationship, setFamilyReferenceRelationship] = useState(Text.dirty(""));
  const [acceptedTerms, setAcceptedTerms] = useState(CheckboxInput.dirty(""));
  const [acceptedOnlineSignature, setAcceptedOnlineSignature] = useState(CheckboxInput.dirtyAllowEmpty("true"));
  const [submitted, setSubmitted] = useState(false);

  const submitForm = () => {
    setSubmitted(true);
    if (!validForm()) return false;

    onSubmit();

    return true;
  };

  const developmentAutoFill = () => {
    if (Environment.env !== 'development') return;

    setPersonalReferenceName(Text.dirty('Personal'));
    setPersonalReferencePhone(Text.dirty('3013016284'));
    setPersonalReferenceEmail(Email.dirty('personal@gmail.com'));
    setPersonalReferenceRelationship(Text.dirty('Referencia personal'));
    setFamilyReferenceName(Text.dirty('Familiar'));
    setFamilyReferencePhone(Text.dirty('3013016284'));
    setFamilyReferenceEmail(Email.dirty('familiar@gmail.com'));
    setFamilyReferenceRelationship(Text.dirty('Referencia familiar'));
    setAcceptedTerms(CheckboxInput.dirty("true"));
  }

  const validForm = (): boolean => {
    return InputValidator.valid([
      personalReferenceName,
      personalReferencePhone,
      personalReferenceEmail,
      personalReferenceRelationship,
      familyReferenceName,
      familyReferencePhone,
      familyReferenceEmail,
      familyReferenceRelationship,
      acceptedTerms
    ]);
  }

  const data = () => {
    return {
      personalReferenceName: personalReferenceName.value,
      personalReferencePhone: personalReferencePhone.value,
      personalReferenceEmail: personalReferenceEmail.value,
      personalReferenceRelationship: personalReferenceRelationship.value,
      familyReferenceName: familyReferenceName.value,
      familyReferencePhone: familyReferencePhone.value,
      familyReferenceEmail: familyReferenceEmail.value,
      familyReferenceRelationship: familyReferenceRelationship.value,
      acceptedOnlineSignature: acceptedOnlineSignature.getBoolean()
    }
  }

  return {
    personalReferenceName, setPersonalReferenceName,
    personalReferencePhone, setPersonalReferencePhone,
    personalReferenceEmail, setPersonalReferenceEmail,
    personalReferenceRelationship, setPersonalReferenceRelationship,
    familyReferenceName, setFamilyReferenceName,
    familyReferencePhone, setFamilyReferencePhone,
    familyReferenceEmail, setFamilyReferenceEmail,
    familyReferenceRelationship, setFamilyReferenceRelationship,
    submitted, submitForm,
    acceptedTerms, setAcceptedTerms,
    data, developmentAutoFill,
    acceptedOnlineSignature, setAcceptedOnlineSignature
  }
};

export default useNewLoanReferencesForm;