import { Box, CircularProgress } from '@mui/material';

interface CreditCapacityCardProps {
  value?: number;
  size?: number;
  thickness?: number;
}

const ProgressIndicator: React.FC<CreditCapacityCardProps> = ({ value = 0, size = 50, thickness = 6 }) => {
  return(
    <Box sx={{ position: 'relative' }} className="flex items-center justify-center">
      <CircularProgress
        variant="determinate"
        sx={(theme) => ({
          color: theme.palette.grey[200],
          ...theme.applyStyles('dark', {
            color: theme.palette.grey[800],
          }),
        })}
        value={100}
        size={size}
        thickness={thickness}
      />
      <CircularProgress
        variant="determinate"
        sx={(theme) => ({
          color: '#8b5cf6',
          position: 'absolute',
          left: 0,
          ...theme.applyStyles('dark', {
            color: '#308fe8',
          }),
        })}
        value={value}
        size={size}
        thickness={thickness}
      />
    </Box>
  )
}
export default ProgressIndicator;