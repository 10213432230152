import { useState } from "react";
import { RestLoansRepository } from "../../infrastructure/repositories";
import { LoansRepository } from "../../domain/repositories";
import PdfUtil from "../../utils/pdf_util";
import { Alert } from "../../utils";

const useLoanContract = (loanId: string) => {
  const [loading, setLoading] = useState(false);
  const [signingUrl, setSigningUrl] = useState<string | null>(null);

  const loansRepository: LoansRepository = RestLoansRepository.getInstance();

  const loadContractFile = async () => {
    setLoading(true);
    const [invoice, errorMessage] = await loansRepository.getContractFile(loanId);
    setLoading(false);

    if (errorMessage) {
      Alert.showError(errorMessage);
    } else {
      PdfUtil.openBlob(invoice!);
    }
  }

  const loadContractSigningUrl = async () => {
    setLoading(true);
    const [url, errorMessage] = await loansRepository.getContractSigningUrl(loanId);
    setLoading(false);

    if (errorMessage) {
      Alert.showError(errorMessage);
    } else {
      setSigningUrl(url!);
    }
  }

  return {
    loading,
    loadContractFile,
    loadContractSigningUrl,
    signingUrl
  }
};

export default useLoanContract;