import { useNavigate, useParams } from "react-router-dom";
import { PageContainer, Splash } from "../components";
import { useLoanContract } from "../hooks";
import { useEffect } from "react";
import Routes from "../../config/Routes";

const SignContractPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    loading, loadContractSigningUrl,
    signingUrl
  } = useLoanContract(id!);

  const handleMessage = (event: any) => {
    const { type } = event.data;

    switch (type) {
      case 'ceremony.completed':
        navigate({
          pathname: Routes.LOAN_DETAIL_PATH.replace(':id', id!),
          search: "?signed=true"
        });
        break;
      case 'ceremony.canceled':
        navigate(Routes.LOAN_DETAIL_PATH.replace(':id', id!), { replace: true });
        break;
      case 'ceremony.failed':
        navigate({
          pathname: Routes.LOAN_DETAIL_PATH.replace(':id', id!),
          search: "?signed=true"
        });
        break;
    }
  }

  useEffect(() => {
    loadContractSigningUrl();
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if(loading || !signingUrl) {
    return(
      <Splash />
    )
  }

  return (
    <PageContainer className="flex-col h-screen">
      <iframe
        id="signatureapi-ceremony"
        title="Sign contract"
        className="w-full h-full shadow-lg	border border-gray-200 rounded-lg mb-16 lg:mb-0"
        src={signingUrl + '&embedded=true&event_delivery=message'}>
      </iframe>
    </PageContainer>
  )
}

export default SignContractPage;